import { get } from 'idb-keyval'
import React, { Fragment, useEffect, useState } from 'react'
import {NavLink } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { ROLES } from './constants'

export default function Aside() {

    const [userData, setUserData] = useState([])
    const cookies = new Cookies()
    // const [userRole, setUserRole] = useState('')

    //crea un efecto para guardar el rol del localstorage
    // useEffect(() => {
    //     get('userRole').then((val) => {
    //         setUserRole(val)
    //     })
    // }, [userRole])

    useEffect(() => {
        const aside = document.querySelector(".aside-menu");

        if (aside) {
            aside.addEventListener("click", e => {
                let aside = document.querySelector("#root > div.aside-bar")
                aside.classList.remove('aside-bar-active')
                aside.classList.add('aside-bar-hidden')
            })
    
        }


        get('userData')
        .then(res => setUserData(res))
        .then(console.log(userData))
    }, [])


    function logout() {
        cookies.remove('id',{path:'/'})
        cookies.remove('email', {path:'/'})
        window.localStorage.clear()
        window.location.pathname = '/login'
    }
    
    return (

        <Fragment> 
{window.location.pathname !== '/login' &&
                <div className='aside-bar aside-bar-hidden'>
                <div className="aside-head">
                    <div className='profile-picture-wrapper'>
                        <img src="/images/man.png" alt="profile"/>
                    </div>

                    <h6>{userData['name'] + " " + userData['surname']}</h6>
                   

                </div>
                <div className='aside-menu'>
                <NavLink className={({isActive}) => isActive ? 'active' : ''} to="/">
                <svg id="home-icon" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                </svg>Inicio</NavLink>
                <NavLink className={({isActive}) => isActive ? 'active' : ''}to="/students">
                <svg id="students-icon" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>Mis alumnos</NavLink>
{/*                 <NavLink className={({isActive}) => isActive ? 'active' : ''}to="/users">
                <svg id="users-icon"xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>Usuarios</NavLink> */}

                {
                    userData.role === "Admin" &&
                    <NavLink className={({isActive}) => isActive ? 'active' : ''}to="/excel">
                    <svg  id="excel-icon"  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 01-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0112 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
                    </svg>Datos</NavLink>

                }

{/* {
                    userData.role === "Admin" &&
                    <NavLink className={({isActive}) => isActive ? 'active' : ''}to="/desarrollo">
                            <svg id="development-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                            </svg>

                        Desarrollo
                    </NavLink>

                } */}

                
{/* 
                {       
                    userData.role === "Admin" &&
                    <NavLink className={({isActive}) => isActive ? 'active' : ''}to="/moments">
                    <svg  id="moment-icon"xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" class="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>Momentos</NavLink>

                } */}

                    <NavLink className={({isActive}) => isActive ? 'active' : ''}to="/respaldo">
                    <svg id="excel-icon" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4" />
                    </svg>Respaldo</NavLink>



                <NavLink className={({isActive}) => isActive ? 'active' : ''}to="/parents">
                <svg id="parents-icon" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>Apoderados</NavLink>

                <NavLink className={({isActive}) => isActive ? 'active' : ''}to="/sessions-logged">
                <svg id="parents-icon" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>Ingresos</NavLink>



                <a href="/login" onClick={logout}>
                    <svg id="logout-icon" xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>   
                    Salir
                </a>
 
                </div>


 

            </div>}
        </Fragment>

    )
}
